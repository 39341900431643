import React, { useEffect, useState } from "react";
import "./App.css";
// import HeatmapBody from "./HeatmapBody";
import BodyCoordinate from "./BodyCoordinate";

function App() {
    const [xyContainer, setXYContainer] = useState([]);
    const [currentPoint, setCurrentPoint] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(100);
    const [imageFillColor, setImageFillColor] = useState("#F1BFA2");

    useEffect(() => {
        document.body.style.zoom = zoomLevel.toString() + "%";
    }, [zoomLevel]);

    useEffect(() => {
        const handleMessage = (event) => {
            try {
                const data = event.data;
                if (data.type === "zoomOut") {
                    handleZoom(false);
                } else if (data.type === "zoomIn") {
                    handleZoom(true);
                } else if (data.type === "flipOrientation") {
                    if (data.activeTab === 0) {
                        //Front
                        setImageFillColor("#F1BFA2");
                    } else {
                        //Back
                        setImageFillColor("#cd611f");
                    }
                } else if (data.type === "addCurrentPoint") {
                    addPoint();
                } else if (data.type === "getPoints") {
                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(
                            JSON.stringify({
                                type: "points",
                                content: "Received your message!",
                                timestamp: new Date().toISOString(),
                                data: xyContainer,
                            })
                        );
                    }
                }
            } catch (error) {
                alert("Error: " + JSON.stringify(error));
            }
        };

        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    });

    function addPoint() {
        if (currentPoint) {
            let filteredResults = xyContainer.filter((point) => point.x === currentPoint.x && point.y === currentPoint.y);
            if (filteredResults && filteredResults.length > 0) {
                console.log(filteredResults);
                return;
            }
            setXYContainer((prevArray) => [...prevArray, { x: currentPoint.x.toString(), y: currentPoint.y.toString(), isAdded: true }]);
            setCurrentPoint(null);
            sendData(xyContainer);
        }
    }
    const sendData = (data) => {
        if (window.ReactNativeWebView) {
            console.log("Sending Message");
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    type: "points",
                    content: "Points updated",
                    timestamp: new Date().toISOString(),
                    data: data,
                })
            );
        }
    };
    const handleZoom = (isIncrement) => {
        if (isIncrement && zoomLevel < 400) {
            document.documentElement.style.setProperty("zoom", (zoomLevel + 15).toString + "%");
            setZoomLevel(zoomLevel + 15);
        }
        if (!isIncrement && zoomLevel > 25) {
            document.documentElement.style.setProperty("zoom", (zoomLevel + 15).toString() + "%");
            setZoomLevel(zoomLevel - 15);
        }

        // document.getElementsByTagName("body")[0].style.setProperty("zoom-level", zoomLevel + "%");
        // document.documentElement.style.setProperty("--zoom-level", zoomLevel + "%");
    };

    return (
        <div style={{ backgroundColor: "#FFF9C7" }}>
            <div className="App">
                <BodyCoordinate xyContainer={xyContainer} setXYContainer={setXYContainer} imageFillColor={imageFillColor} currentPoint={currentPoint} setCurrentPoint={setCurrentPoint} sendData={sendData} />
            </div>
            <button onClick={addPoint} style={{ display: "none" }} id="addButtonId">
                Add
            </button>
        </div>
    );
}

export default App;
